import React from 'react';
import styled from 'styled-components';
import {message, Table, Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import {navigate} from 'gatsby';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {timestampFormat} from '../Utils/DateUtil';
import {errorHandler} from '../errors';
const appConfig = require('../data.json');

function CategoryList(props) {
  const [loading, setLoading] = useOutlet('loading');
  const [categories, setCategories] = React.useState(null);
  const [actions] = useOutlet('actions');

  const fetchRecords = React.useCallback(
    async ({sort, keyword}) => {
      let query = keyword
        ? {
            name: {$regex: `${keyword}`},
          }
        : {};

      setLoading(true);

      try {
        let resp = await JStorage.fetchDocuments(
          'categories',
          query,
          sort ? [sort] : [],
          {offset: 0, limit: 50},
        );

        let _categoriesDisplay = {
          faqs: 'FAQ',
          articles: '文章',
          products: '全部商品',
          hashtags: 'hashtags',
          works: 'works',
        };

        resp = resp.results.map((element) => ({
          ...element,
          display: _categoriesDisplay[`${element.name}`],
        }));

        if (appConfig.isLiteVersion) {
          resp = resp.filter((o) => o.name !== 'articles');
        }

        setCategories(resp);
      } catch (err) {
        message.error('發生錯誤，請稍後再試。');
        console.warn('err', err);
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  const onUpdateBuildHook = React.useCallback(async () => {
    actions.setLoading(true);
    try {
      if (window.confirm('確定要更新前台？')) {
        await actions.rebuild();
        message.success('更新成功，前台需要部署時間，請稍後查看。');
      }
    } catch (err) {
      await actions.getJwtToken();
      errorHandler(err);
    }
    actions.setLoading(false);
  }, [actions]);

  React.useEffect(() => {
    if (!categories) {
      fetchRecords({sort: '', keyword: ''});
    }
  }, [categories, fetchRecords]);

  return (
    <Wrapper>
      <div className="top-container">
        <Button type="primary" onClick={() => onUpdateBuildHook()}>
          更新前台
        </Button>
      </div>

      <Table
        loading={loading}
        expandIconColumnIndex={-1}
        dataSource={categories}
        columns={[
          {key: 'display', title: '分類名稱', dataIndex: 'display'},
          {
            key: 'created',
            title: '建立日期',
            dataIndex: 'created',
            render: (_, record) => <div>{timestampFormat(record.created)}</div>,
          },
          {
            key: 'action',
            title: '',
            dataIndex: '',
            render: (_, record) => (
              <div>
                <Button
                  onClick={() =>
                    navigate(
                      `/category?action=detail&&id=${record.id}&display=${record.display}`,
                    )
                  }>
                  詳細
                </Button>
              </div>
            ),
          },
        ]}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f5f5f5;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > .top-container {
    margin-bottom: 20px;
  }
`;

export default CategoryList;
